import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect } from "react"
import { Col, Container, Row, useScreenClass, Visible } from "react-grid-system"
import { useInView } from "react-intersection-observer"
import { Controller, Scene } from "react-scrollmagic"
import styled from "styled-components"
import CtaBanner from "../components/ctaBanner"
import ExpertiseList from "../components/expertise/expertiseList"
import RelatedInsights from "../components/insights/relatedInsights"
import Layout from "../components/layout"
import SEO from "../components/seo"

//
// Main content section
//

const StyledMain = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin-top: 0;
  padding-top: 200px;
`

const SectionTop = styled.div`
  margin-bottom: 9.75rem;
  overflow: hidden;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    margin-bottom: 2.72rem;
  }
`

const ColPush = styled(Col)`
  order: 1;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    order: 1;
  }
`

const ColPull = styled(Col)`
  order: 1;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    order: 0;
  }
`

const SidebarImage = styled.div`
  height: auto;
  position: relative;
  width: 50vw;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    margin-top: 1.5rem;
    width: calc(100vw - 60px);
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    margin-bottom: 1.5rem;
    margin-top: 0;
    width: calc(100vw - 23px);
  }
`

const SidebarImageBg = styled(GatsbyImage)`
  width: 100%;
`

const SidebarImageFg = styled(GatsbyImage)`
  bottom: 2.5rem;
  height: auto;
  left: 3.75rem;
  max-width: 674px;
  position: absolute !important;
  top: 2.5rem;
  width: 100%;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    max-width: calc(100vw - 270px);
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    bottom: 1.43rem;
    left: 1.8rem;
    max-width: calc(100vw - 92px);
    top: 1.43rem;
  }
`

const SectionExpertise = styled.div``

const ExpertiseWrapper = styled.div`
  margin-top: 3rem;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    margin-top: 0;
  }
`

const SectionAnchor = styled.a`
  height: 0;
  left: 0;
  position: absolute;
  top: -100px;
  width: 0;
  visibility: hidden;
`
//
// HERO slides
//

const Heroes = styled.section``

const HeroSlide = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  margin-top: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
`

const HeroImg = styled(GatsbyImage)`
  height: 100%;
  min-height: 100vh;
  width: 100%;
`

const HeroContent = styled.div`
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  width: 100%;
`

const HeroIndex = styled.p`
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 1.55rem;
  line-height: 1.05;
  margin-bottom: 1em;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
  }
`

const HeroHeadline = styled.h1`
  margin-bottom: 1rem;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    margin-bottom: 2em;
    &::before {
      display: none;
    }
  }
`

const HeroText = styled.div`
  margin-bottom: 1rem;
`

const HeroCta = styled.a`
  box-sizing: border-box;
  max-width: 260px;
  width: 260px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    max-width: 100%;
    width: 100%;
  }
`

const SectionHeadline = styled.h2`
  font-size: 3rem;
  margin-bottom: 0.67em;
`

//
// Page export
//

const HomePage = (props) => {
  const {
    relatedInsights,
    seoTitle,
    seoDescription,
    seoHelmet,
    openGraphImage,
  } = props.data.contentfulPage

  const screenClass = useScreenClass()
  const heroCount = props.data.allContentfulHeroImages.edges.length
  const [refMain, inView, entry] = useInView({
    rootMargin: "0px 0px -99% 0px",
  })

  useEffect(() => {
    console.log("Inview: ", inView)
    if (inView) {
      document.body.classList.add("is-intersecting__home__main")
    } else {
      document.body.classList.remove("is-intersecting__home__main")
    }
  }, [inView])

  console.log(props.data.homeNurtureBackground)
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
        image={openGraphImage?.file?.url}
      />
      <Layout className="home">
        <Heroes>
          <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
            {props.data.allContentfulHeroImages.edges.map(
              ({ node: hero }, index) => (
                <Scene
                  key={index}
                  pin
                  classToggle={[`body`, `is-intersecting__hero-${index}`]}
                >
                  <HeroSlide key={index}>
                    <Visible md lg xl>
                      <HeroImg image={hero.desktop.gatsbyImageData} />
                    </Visible>
                    <Visible xs sm>
                      <HeroImg image={hero.mobile.gatsbyImageData} />
                    </Visible>
                    <HeroContent>
                      <Container
                        style={{
                          paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                            ? "3rem"
                            : "1.5625rem",
                          paddingRight: ["md", "lg", "xl"].includes(screenClass)
                            ? "3rem"
                            : "1.5625rem",
                        }}
                      >
                        <Row>
                          <Col lg={8} offset={{ lg: 2 }}>
                            <HeroIndex>{`0${
                              index + 1
                            } / 0${heroCount}`}</HeroIndex>
                            <HeroHeadline
                              dangerouslySetInnerHTML={{
                                __html: hero.headline,
                              }}
                              className={`headline`}
                            />
                            <Visible md lg xl>
                              <HeroText>
                                {documentToReactComponents(
                                  JSON.parse(hero.text.raw)
                                )}
                              </HeroText>
                            </Visible>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} offset={{ lg: 2 }}>
                            <HeroCta
                              href={hero.ctaLink}
                              className={`button button-white-outline`}
                            >
                              {hero.cta}
                            </HeroCta>
                          </Col>
                        </Row>
                      </Container>
                    </HeroContent>
                  </HeroSlide>
                </Scene>
              )
            )}
          </Controller>
        </Heroes>
        <StyledMain ref={refMain} id={`home__main`} className={`bg-white`}>
          <SectionTop>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <ColPush
                  lg={5}
                  xl={4}
                  offset={{ lg: 1, xl: 2 }}
                  style={{
                    paddingRight: ["lg", "xl"].includes(screenClass)
                      ? "2rem"
                      : "8px",
                  }}
                >
                  <SectionHeadline className={`headline`}>
                    {props.data.contentfulPage.mainHeadline &&
                      props.data.contentfulPage.mainHeadline}
                    {!props.data.contentfulPage.mainHeadline && (
                      <span>
                        Brand. Attract. Nurture. <b>Grow.</b>
                      </span>
                    )}
                  </SectionHeadline>
                  {props.data.contentfulPage.text &&
                    props.data.contentfulPage.text.raw &&
                    documentToReactComponents(
                      JSON.parse(props.data.contentfulPage.text.raw)
                    )}
                  <Link to={`/work/`} className={`button`}>
                    See our work
                  </Link>
                </ColPush>
                <ColPull lg={6}>
                  <SidebarImage>
                    <SidebarImageBg
                      image={
                        props.data.homeNurtureBackground.childImageSharp
                          .gatsbyImageData
                      }
                    />
                    <SidebarImageFg
                      image={
                        props.data.homeNurtureForeground.childImageSharp
                          .gatsbyImageData
                      }
                    />
                  </SidebarImage>
                </ColPull>
              </Row>
            </Container>
          </SectionTop>
          <SectionExpertise>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col xl={3} offset={{ lg: 2 }}>
                  <SectionHeadline className={`headline`}>
                    Expertise
                  </SectionHeadline>
                </Col>
                <Col lg={8} xl={6} offset={{ lg: 2, xl: 0 }}>
                  <ExpertiseWrapper>
                    <SectionAnchor id="services" />
                    <ExpertiseList />
                  </ExpertiseWrapper>
                </Col>
              </Row>
            </Container>
          </SectionExpertise>
          {relatedInsights && <RelatedInsights posts={relatedInsights} />}
          <CtaBanner {...props.data.contentfulPage.cta} />
        </StyledMain>
      </Layout>
    </>
  )
}

export const query = graphql`
  query IndexQuery {
    homeNurtureBackground: file(relativePath: { eq: "bg-nurture-grow.png" }) {
      childImageSharp {
        gatsbyImageData(width: 960, quality: 95, layout: CONSTRAINED)
      }
    }
    homeNurtureForeground: file(relativePath: { eq: "home-brand-grow.png" }) {
      ...homeBrandImage
    }
    allContentfulHeroImages(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          id
          sortOrder
          name
          headline
          text {
            raw
          }
          desktop {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 95)
          }
          mobile {
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 95)
          }
          cta
          ctaLink
        }
      }
      totalCount
    }
    contentfulPage(slug: { eq: "home" }) {
      id
      slug
      name
      mainHeadline
      text {
        raw
      }
      relatedInsights: insights {
        id
        slug
        title
        preview
        image {
          localFile {
            ...insightsThumbnail
          }
        }
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
      openGraphImage {
        file {
          url
        }
      }
      cta {
        id
        name
        ctaTitle
        ctaBody
        ctaButton
      }
    }
  }
`

export default HomePage
